import { parseQueryString } from "./utils";
import {
  addUtmQuerystringsToLocalStorage,
  utm,
  utm_source,
  utm_campaign,
  utm_content,
  utmMapping,
} from "./utmTracking";

declare var fieldIdsToHide: string; // eslint-disable-line no-var
declare var MsCrmMkt: any; // eslint-disable-line no-var

const dynamicsFormQuerySelectorMappings: { [key: string]: string } = {
  [utm]: "input[name='c09ef87b-eb7a-eb11-a812-0022481a889a']",
  [utm_source]: "select[name='60a02fc6-b759-ec11-8f8f-000d3a0d0cfb']",
  [utm_campaign]: "input[name='43bbad09-9061-ec11-8f90-0022481b463c']",
  [utm_content]: "input[name='f516b5f6-8f61-ec11-8f90-0022481b463c']",
};

addUtmQuerystringsToLocalStorage();

let querystringParams = parseQueryString();

const populateUtmFields = () => {
  for (let querystringKey in utmMapping) {
    const localStorageVal = localStorage.getItem(utmMapping[querystringKey]);
    const qsVal = querystringParams.get(querystringKey);
    let utmVal = qsVal || localStorageVal;
    if (querystringKey === "utm") {
      utmVal = utmVal || window.location.search;
      let utmFeedback = "";
      let utmSource = localStorage.getItem("lodlawMarketingUtmSource");
      if (utmSource) {
        utmFeedback += `?utm_source=${utmSource}`;
      }
      let utmMedium = localStorage.getItem("lodlawMarketingUtmMedium");
      if (utmMedium) {
        utmFeedback += `&utm_medium=${utmMedium}`;
      }
      let utmCampaign = localStorage.getItem("lodlawMarketingUtmCampaign");
      if (utmCampaign) {
        utmFeedback += `&utm_campaign=${utmCampaign}`;
      }
      let utmContent = localStorage.getItem("lodlawMarketingUtmContent");
      if (utmContent) {
        utmFeedback += `&utm_content=${utmContent}`;
      }
      utmVal = utmVal || utmFeedback;
    }

    if (utmVal) {
      let inputArr = document.querySelectorAll(
        dynamicsFormQuerySelectorMappings[querystringKey],
      );
      for (let input of inputArr) {
        if (input.tagName === "SELECT") {
          for (let option of (<HTMLSelectElement>input).options) {
            if (option.text === utmVal) {
              (<HTMLSelectElement>input).value = option.value;
            }
          }
        } else {
          (<HTMLInputElement>input).value = utmVal;
        }
      }
    }
  }
};

const populateReferrerFields = () => {
  let referrer = localStorage.getItem("referrer");
  if (referrer) {
    let inputArr = document.querySelectorAll(
      "input[name='79d91816-0286-eb11-a812-0022481a7ba8']",
    );
    for (let input of inputArr) {
      (<HTMLInputElement>input).value = referrer;
    }
  }
};

const removeSelectPlaceholdersOnChange = () => {
  for (let select of document.querySelectorAll("select")) {
    select.addEventListener("change", function () {
      this.classList.remove("selectPlaceholder");
    });
  }
};

const udpateDynamicsFormSyles = () => {
  let fullWidthFieldContainer = <any>(
    document.getElementById("23565bd8-b759-ec11-8f8f-000d3a0d0cfb")
  );
  let count = 0;
  // Iterate updwards through DOM, setting max width of parents to 100%
  while (
    fullWidthFieldContainer &&
    fullWidthFieldContainer.tagName !== "FORM" &&
    count++ < 9
  ) {
    fullWidthFieldContainer.style["max-width"] = "100%";
    fullWidthFieldContainer = fullWidthFieldContainer?.parentNode;
  }
};

const hideFormFields = () => {
  if (typeof fieldIdsToHide === "undefined") {
    return;
  }

  const fieldsToHideArray = fieldIdsToHide
    .split(",")
    .filter((fieldId) => fieldId !== "")
    .map((fieldId) => fieldId.trim());

  fieldsToHideArray.forEach((fieldId) => {
    document
      .querySelectorAll(`input[id='${fieldId}'], label[for='${fieldId}']`)
      .forEach((field) => {
        field.remove();
      });
  });
};

const showDynamicsForms = () => {
  document.querySelectorAll('form[data-container="true"]').forEach((form) => {
    form.classList.add("show-form");
  });
};

const updateDynamicsForms = () => {
  populateUtmFields();
  populateReferrerFields();
  removeSelectPlaceholdersOnChange();
  hideFormFields();
  udpateDynamicsFormSyles();
  showDynamicsForms();
};

if (typeof MsCrmMkt !== "undefined") {
  MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", () => {
    updateDynamicsForms();
  });

  // Also adding event listener in case of an error with MsCrmFormLoader afterFormLoad event
  window.addEventListener("load", () => {
    updateDynamicsForms();
  });
}
