import { Dict } from "./utils/reduxRequests";

export function convertObjectToFormData(
  object: Dict<string | Blob | File | undefined | null>,
): FormData {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    const value = object[key];
    if (value) {
      formData.append(key, value);
    }
  });
  return formData;
}

export interface Location {
  area: string;
  location: string;
  redirect?: string;
}

export interface LocationGroup {
  area: string;
  locations: ReadonlyArray<string>;
}

export type LocationsGroups = ReadonlyArray<LocationGroup>;

export function groupLocations(
  locations: ReadonlyArray<Location>,
): LocationsGroups {
  return locations.reduce((previousResult: LocationsGroups, location) => {
    const hasExistingGroup = Boolean(
      previousResult.find((group) => group.area === location.area),
    );

    return hasExistingGroup
      ? previousResult.map((group) =>
          group.area === location.area
            ? { ...group, locations: [...group.locations, location.location] }
            : group,
        )
      : [
          ...previousResult,
          { area: location.area, locations: [location.location] },
        ];
  }, []);
}

export const concatClassNameWithDash = (name: string | undefined) =>
  name ? `-${name}` : "";

export const parseQueryString = () => {
  let str = window.location.search;
  let objURL = new Map<string, string>();

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    (originalString, key, __, val) => {
      objURL.set(key, val);
      return originalString;
    },
  );

  return objURL;
};
