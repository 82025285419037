import { parseQueryString } from "./utils";
import * as cookie from "cookie";

const updateUtmLocalStorage = (
  querystringKey: string,
  querystringVal: string,
  localStorageKey: string,
) => {
  const localStorageVal =
    querystringKey === "utm" ? window.location.search : querystringVal; // Ensures consistency with old functionality
  localStorage.setItem(localStorageKey, localStorageVal);
  localStorage.setItem(`${localStorageKey}DatetimeSet`, Date.now().toString());
};

const removeUtmLocalStorage = (localStorageKey: string) => {
  localStorage.removeItem(localStorageKey);
  localStorage.removeItem(`${localStorageKey}DatetimeSet`);
};

let querystringParams = parseQueryString();

export const utm = "utm";
export const utm_source = "utm_source";
export const utm_campaign = "utm_campaign";
export const utm_content = "utm_content";
export const utm_medium = "utm_medium";
export const utm_term = "utm_term";

export const utmMapping: { [key: string]: string } = {
  [utm]: "lodlawMarketingUtm",
  [utm_source]: "lodlawMarketingUtmSource",
  [utm_campaign]: "lodlawMarketingUtmCampaign",
  [utm_content]: "lodlawMarketingUtmContent",
  [utm_medium]: "lodlawMarketingUtmMedium",
  [utm_term]: "lodlawMarketingUtmTerm",
};

export const addUtmQuerystringsToLocalStorage = () => {
  if (cookie.parse(document.cookie)["analytics-cookies-accepted"] == "true") {
    for (let querystringKey in utmMapping) {
      const querystringVal = querystringParams.get(querystringKey);
      const localStorageKey = utmMapping[querystringKey];
      if (typeof querystringVal === "string") {
        updateUtmLocalStorage(querystringKey, querystringVal, localStorageKey);
      } else {
        const datetimeUtmSet = localStorage.getItem(
          `${localStorageKey}DatetimeSet`,
        );
        if (
          datetimeUtmSet &&
          Date.now() - parseInt(datetimeUtmSet) > 1000 * 60 * 60 * 24
        ) {
          removeUtmLocalStorage(localStorageKey);
        }
      }
    }
  }
};
